import React from 'react'

import Layout from '../components/Layout'
import { Header, LinkText } from '../components/Typography'
import { Container, Links, LinkItem } from '../components/TextElements'
import links from '../config/links'

const IndexPage = () => (
  <Layout>
    <Container>
      <Header>Aki Rautio</Header>
      <Links>
        <LinkItem>
          <LinkText href={links.linkedin} aria-label="My LinkedIn profile">
            LinkedIn
          </LinkText>
        </LinkItem>
        <LinkItem>
          <LinkText href={links.github} aria-label="My Github profile">
            Github
          </LinkText>
        </LinkItem>
        <LinkItem>
          <LinkText
            href={links.devto}
            aria-label="My DevTo profile for blog posts"
          >
            DevTo
          </LinkText>
        </LinkItem>
        <LinkItem>
          <LinkText
            href={links.twitter}
            aria-label="My Twitter for social networking"
          >
            Twitter
          </LinkText>
        </LinkItem>
      </Links>
    </Container>
  </Layout>
)

export default IndexPage
